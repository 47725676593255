import { DialogIdent, useDialogStore } from '@/stores/useDialogStore';

export default function useChangeBankDataDialog() {
  const dialogStore = useDialogStore();
  type openOptions = {
    isEdit: boolean;
  };
  return {
    open: async (options: openOptions) => {
      return dialogStore.openDialog(DialogIdent.NEW_EDIT_BANKDATA, options);
    },
    getData: () => {
      return dialogStore.getDialogData<openOptions>(
        DialogIdent.NEW_EDIT_BANKDATA,
      );
    },
    close: (data?: any) => {
      dialogStore.closeDialog(data);
    },
    setHeadline: (headline: string) => dialogStore.setHeadline(headline),
  };
}
