<template>
  <div>
    <div class="relative flex items-center gap-xs">
      <FormLabel :label="t('bankDataInput.billingCycle.heading')" />
    </div>

    <RadioButton
      name="favLists"
      :model-value="modelValue"
      :options="[
        {
          value: false,
          label: t('shop.myOrders.invoice.paymentTerms.TR'),
          attrs: {
            disabled: disabledOptions,
          },
        },
        {
          value: true,
          label: t('bankDataInput.colleciveInvoice.label'),

          attrs: {
            disabled: disabledOptions,
          },

          hint: true,
        },
      ]"
      @update:model-value="(newValue) => setCollectiveInvoice(newValue)"
    >
      <template #label="{ context }">
        <span class="custom-label">{{ context.option?.label }}</span>
        <div v-if="context.option?.hint" class="ml-2xs pt-[5px]">
          <DynamicPopup>
            <template #content>
              <InfoFrame class="max-w-[450px]">
                <div>
                  {{ t('bankDataInput.colleciveInvoice.hint') }}
                </div>
              </InfoFrame>
            </template>
          </DynamicPopup>
        </div>
      </template>
    </RadioButton>
  </div>
</template>

<script setup lang="ts">
import { FormLabel, RadioButton, DynamicPopup, InfoFrame } from '@/complib';

defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  disabledOptions: {
    type: Boolean,
    default: false,
  },
});
const { t } = useTrans();

function setCollectiveInvoice(val: boolean) {
  emit('setCollectiveInvoice', val);
}

const emit = defineEmits<{
  (e: 'setCollectiveInvoice', value: boolean): void;
}>();
</script>
